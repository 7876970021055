import { Component } from '@angular/core';
import { Auth, connectAuthEmulator } from '@angular/fire/auth';
import { Firestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { Functions, connectFunctionsEmulator } from '@angular/fire/functions';
import { Storage, connectStorageEmulator } from '@angular/fire/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private firestore: Firestore, private auth: Auth, private storage: Storage, private functions: Functions) {
    if (window.location.hostname === 'localhost') {
      connectFirestoreEmulator(firestore, 'localhost', 8080);
      connectAuthEmulator(auth, 'http://localhost:9099');
      connectStorageEmulator(storage, 'localhost', 9199);
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }
  }
}
