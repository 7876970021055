// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'locally-776e6',
    appId: '1:421112556170:web:ab56eb7f071240ee9e5b73',
    storageBucket: 'locally-776e6.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyD15IM7NgXMp1zh2vWLjCRRNFHrNGjmA1c',
    authDomain: 'locally-776e6.firebaseapp.com',
    messagingSenderId: '421112556170',
    measurementId: 'G-21W3L7PC7S',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
