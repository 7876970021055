import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Auth, authState, getIdTokenResult } from '@angular/fire/auth';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private firestore: Firestore,
    private auth: Auth
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const url = route['_routerState'].url;
      if (url == '/') {
        return await this.skipOnboarding(route);
      } else {
        const authUser = await authState(this.auth).pipe(take(1)).toPromise();
        if (authUser) {
          const tokenRes = await getIdTokenResult(authUser);
          const userProfile = await this.getUserProfile(tokenRes);
          const isAuthenticated = await this.checkAuthentication(route, tokenRes, userProfile);
          if (!isAuthenticated) {
            this.router.navigate(['app/shops']);
            return false;
          }
          return true;
        } else {
          this.router.navigate(['app/shops']);
          return false;
        }
      }

    } catch (error) {
      console.error('Error checking authentication:', error);
      return true;
    }
  }

  async skipOnboarding(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const authUser = await authState(this.auth).pipe(take(1)).toPromise();
      if (authUser) {
        const tokenRes = await getIdTokenResult(authUser);
        const userProfile = await this.getUserProfile(tokenRes);
        if (userProfile) {
          this.router.navigate(['app/shops']);
          return false;
        }
        return true;
      } else {
        this.router.navigate(['onboarding']);
        return false;
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      return true;
    }
  }

  private async getUserProfile(tokenRes): Promise<any> {
    const userDoc = doc(this.firestore, 'users', tokenRes.claims.user_id);
    const userProfile = await docData(userDoc, { idField: 'id' }).pipe(take(1)).toPromise();
    return userProfile;
  }

  private async checkAuthentication(route, tokenRes, userProfile): Promise<boolean> {
    const url = route['_routerState'].url;
    const isAdmin = tokenRes.claims.roles?.admin;
    let isShopOwner;
    if (route.params.shopId) {
      isShopOwner = userProfile && userProfile?.shops[route.params.shopId]?.roles.admin;
    } else {
      const shopId = url.split('/')[2];
      isShopOwner = userProfile && userProfile?.shops[shopId]?.roles.admin;
    }

    if (url.includes('admin')) {
      return isAdmin;
    } else if (url.includes('/edit') || url.includes('/add')) {
      return isAdmin || (userProfile && isShopOwner);
    } else if (url.includes('app/user/')) {
      return isAdmin || (route.params.userId === tokenRes.claims.user_id);
    } else if (url.includes('app/orders/order/')) {
      const order = await this.getOrder(route.params.orderId);
      return isAdmin || (order.user.id === tokenRes.claims.user_id);
    } else if (url.startsWith('/shop/')) {
      return isAdmin || (userProfile && isShopOwner);
    }

    return true;
  }

  private async getOrder(orderId): Promise<any> {
    const orderDoc = doc(this.firestore, 'orders', orderId);
    const order = await docData(orderDoc, { idField: 'id' }).pipe(take(1)).toPromise();
    return order;
  }
}
